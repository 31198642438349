import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.sort.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  name: "login-log",
  props: {
    logs: {
      type: Array,
      required: true,
      default: function _default() {
        return new Array();
      }
    },
    user: {
      type: Number,
      default: 0,
      required: true
    }
  },
  watch: {
    logs: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        if (param && param.length > 0) {
          var temp = this.lodash.cloneDeep(param);
          temp.sort(function compare(a, b) {
            var dateA = new Date(b.datetime);
            var dateB = new Date(a.datetime);
            return dateA - dateB;
          });
          this.loglist = this.lodash.cloneDeep(temp);
        }
      }
    }
  },
  data: function data() {
    return {
      loglist: []
    };
  }
};