import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  name: "recent-activity",
  props: {
    user: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data: function data() {
    return {
      pageLoading: true,
      recentActivity: []
    };
  },
  methods: {
    getUserActivity: function getUserActivity() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(GET, {
            url: "user/" + _this.user + "/recent-activity"
          }).then(function (response) {
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.getUserActivity().then(function (response) {
      _this.recentActivity = response;
    }).catch(function (error) {
      _this.logError(error);
    }).finally(function () {
      _this.pageLoading = false;
    });
  }
};