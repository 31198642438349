import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ValidationMixin from "@/core/plugins/validation-mixin";
import { PATCH } from "@/core/services/store/request.module";
export default {
  name: "password-update",
  mixins: [ValidationMixin],
  props: {
    user: {
      type: Number,
      default: 0,
      required: true
    },
    isOldPassword: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      formValid: true,
      formLoading: false,
      formData: {
        old_password: null,
        password: null,
        password_confirmation: null
      }
    };
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this = this;

      if (!_this.$refs.passwordForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      var url = "user/" + _this.user + "/password";

      if (_this.isOldPassword) {
        url = "user/reset-password";
      }

      _this.$store.dispatch(PATCH, {
        url: url,
        data: _this.formData
      }).then(function () {
        _this.formValid = true;
        _this.formData = {
          old_password: null,
          password: null,
          password_confirmation: null
        };

        _this.$emit("password:updated");
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    }
  }
};