import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import Dialog from "@/view/pages/partials/Dialog";
import { GET, PATCH } from "@/core/services/store/request.module";
import LoginLog from "@/view/pages/user/detail/LoginLog";
import PasswordUpdate from "@/view/pages/user/detail/PasswordUpdate";
import RecentActivity from "@/view/pages/user/detail/RecentActivity";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "user-detail",
  data: function data() {
    return {
      user: 0,
      userTab: null,
      pageLoading: true,
      roleLoading: false,
      updateDialog: false,
      formValid: true,
      formLoading: false,
      loginLogs: [],
      userArr: {},
      roles: [],
      tabs: [{
        title: "Recent Activities",
        icon: "mdi-credit-card",
        key: "history",
        disabled: false
      }, {
        title: "Login Log",
        icon: "mdi-credit-card",
        key: "login-log",
        disabled: false
      }, {
        title: "Password",
        icon: "mdi-credit-card",
        key: "password",
        disabled: false
      }],
      updateUser: {
        role: null,
        title: null,
        first_name: null,
        last_name: null,
        display_name: null
      }
    };
  },
  methods: {
    submitUser: function submitUser() {
      var _this = this;

      if (!_this.$refs.userForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "user/" + _this.user,
        data: _this.updateUser
      }).then(function () {
        _this.updateDialog = false;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.loadContent();

        _this.formLoading = false;
      });
    },
    getUser: function getUser() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          _this.$store.dispatch(GET, {
            url: "user/" + _this.user
          }).then(function (response) {
            resolve(response.data);
          }).catch(function (error) {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getRoles: function getRoles() {
      var _this = this;

      _this.roleLoading = true;
      _this.updateUser = {
        role: _this.userArr.role ? _this.userArr.role.id : 0,
        title: _this.lodash.lowerCase(_this.userArr.title),
        first_name: _this.userArr.first_name,
        last_name: _this.userArr.last_name,
        display_name: _this.userArr.display_name
      };

      _this.$store.dispatch(GET, {
        url: "role"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.roles = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.updateDialog = true;
        _this.roleLoading = false;
      });
    },
    loadContent: function loadContent() {
      var _this = this;

      _this.pageLoading = true;

      _this.getUser().then(function (response) {
        _this.userArr = response;
        _this.loginLogs = response.login_details || [];
      }).catch(function (error) {
        _this.logError(error);

        _this.$router.go(-1);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  components: {
    DetailTemplate: DetailTemplate,
    LoginLog: LoginLog,
    Dialog: Dialog,
    PasswordUpdate: PasswordUpdate,
    RecentActivity: RecentActivity
  },
  mounted: function mounted() {
    var _this = this;

    _this.loadContent();

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "User",
      route: "user"
    }, {
      title: "Detail"
    }]);
  },
  created: function created() {
    var _this = this;

    _this.user = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (!_this.user || _this.user <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    getPageTitle: function getPageTitle() {
      return this.userArr ? this.userArr.full_name : "";
    }
  }
};